import MediaTrackSourceScreen from "./MediaTrackSourceScreen";
import MediaTrackSourceCamera from "./MediaTrackSourceCamera";
import MediaTrackSourceAudio from "./MediaTrackSourceAudio";
export default class MediaTrackSourceCenter {
  constructor() {
    this.MediaTrackSourceScreen = new MediaTrackSourceScreen();
    this.MediaTrackSourceCamera = new MediaTrackSourceCamera();
    this.MediaTrackSourceAudio = new MediaTrackSourceAudio();
  }
  _instance = null;
  //媒体源
  MediaTrackSourceScreen = null;
  MediaTrackSourceCamera = null;
  MediaTrackSourceAudio = null;
  static GetInstance() {
    if (this._instance == null) {
      this._instance = new MediaTrackSourceCenter();
    }
    return this._instance;
  }
}
