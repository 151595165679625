export default {
  /// <summary>
  /// 普通文本数据
  /// </summary>
  Text: 0,
  /// <summary>
  /// 字节流
  /// </summary>
  Binary: 1,
  /// <summary>
  /// 请求
  /// </summary>
  Request: 2,
  /// <summary>
  /// 响应
  /// </summary>
  Response: 3,
  /// <summary>
  /// [客户端]心跳检测包
  /// </summary>
  HeartBeat: 4,
};
