import Tools from "./Tools";
import DatapackType from "./DatapackType";

export default class Message {
  constructor(length, datapackType) {
    this.Length = length;
    this.DatapackType = datapackType;
    this.Offset = 0;
    this.Stream = new Uint8Array(this.Length);
  }
  Add(message) {
    this.Stream[this.Offset] = message;
    this.Offset++;
  }
  IsComplete() {
    var iscomplete = this.Offset >= this.Length;
    return iscomplete;
  }
  GetDatapackType() {
    return this.DatapackType;
  }
  GetString() {
    var textDecoder = new TextDecoder("utf-8");
    var message = textDecoder.decode(this.Stream);
    return message;
  }
  GetBytes() {
    const bytes = Array.prototype.slice.call(this.Stream);
    return bytes;
  }
  BuildMessage(bytes, datapackType) {
    if (!bytes) {
      return [];
    }
    var type = this.ConvertDatapackType(datapackType);
    var typeByte = Tools.IntToBytes(type, 1);
    var lengthByte = Tools.IntToBytes(bytes.length, 4);
    var data = [...typeByte, ...lengthByte, ...bytes];
    return new Uint8Array(data);
  }
  ConvertDatapackType(datapackType) {
    let type = 0;
    if (datapackType == DatapackType.Text) {
      type = 0;
    } else if (datapackType == DatapackType.HeartCheck) {
      type = 1;
    } else if (datapackType == DatapackType.Request) {
      type = 2;
    } else if (datapackType == DatapackType.BytesHeader) {
      type = 3;
    } else if (datapackType == DatapackType.Bytes) {
      type = 4;
    }
    return type;
  }
}
