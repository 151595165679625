import Request from "./internal/Request";
import DatapackType from "../transport/DatapackType";
export default class RequestCenter {
  constructor(rtcDataChannel) {
    this.RTCDataChannel = rtcDataChannel;
    this.Requests = [];
  }
  SendRequest(request) {
    if (request.__proto__ != Request.prototype) {
      throw new Error("只允许Request请求类型");
    }
    this.Requests.push(request);
    request.OnTimeout = (error) => {
      var index = this.Requests.findIndex((item) => item == request);
      if (index != -1) {
        this.Requests.splice(index, 1);
      }
    };
    request.StartCalcTimeout();
    const message = JSON.stringify(request);
    var textEncoder = new TextEncoder("utf-8");
    this.RTCDataChannel.Send(textEncoder.encode(message), DatapackType.Request);
  }
  HandleResponse(bytes) {
    var textDecoder = new TextDecoder("utf-8");
    const message = textDecoder.decode(new Uint8Array(bytes));
    const response = JSON.parse(message);
    const index = this.Requests.findIndex((item) => item.RequestId == response.RequestId);
    if (index != -1) {
      var request = this.Requests[index];
      this.Requests.splice(index, 1);
      request.StopCalcTimeout();
      if (request.OnResponse) {
        request.OnResponse(response);
      }
    }
  }
  HandleText(data) {
    var textDecoder = new TextDecoder("utf-8");
    const message = textDecoder.decode(new Uint8Array(data));
    if (message == "停止播放") {
      this.RTCPeerConnection.RTCPeerConnection.onStopPlayVideo?.();
    }
  }
}
