export default {
  /// <summary>
  /// 直接发送信息
  /// </summary>
  Any: "Any",
  /// <summary>
  /// 加入房间
  /// </summary>
  Join: "Join",
  /// <summary>
  /// 退出房间
  /// </summary>
  Leave: "Leave",
  /// <summary>
  /// 发起连接消息
  /// </summary>
  Call: "Call",
  /// <summary>
  /// 发送给指定客户端
  /// </summary>
  Target: "Target",
};
