export default class MediaTrackSourceBase {
  _useCount = 0;
  TrackSource = null;
  Use() {
    this._useCount++;
  }
  Free() {
    this._useCount--;
  }
  Dispose() {
    this.Free();
    if (this._useCount < 0) {
      console.log("计数异常");
    }
    if (this._useCount <= 0) {
      try {
        this.TrackSource?.getTracks().forEach((track) => track.stop());
        this.TrackSource = null;
      } catch (Exception) {}
    }
  }
}
