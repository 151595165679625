import adapter from "webrtc-adapter";
import Vue from "vue";
import { mapState } from "vuex";
import xSocket from "@/assets/xSocket";
import Webrtc from "@/webrtc";
import RoomInfo from "@/webrtc/models/RoomInfo";
import ClientInfo from "@/webrtc/models/ClientInfo";
import RequestCenter from "@/webrtc/server/RequestCenter";
import DatapackType from "@/webrtc/transport/DatapackType";

console.log(adapter.browserDetails.browser);
export default {
  data() {
    return {
      AllowWebrtc: true,
      WebsocketUrl: "",
      Identity: "",
      Functions: "Data", //"None,Screen,Camera,Audio,Data"
      Websocket: null,
      WebsocketConnected: false,
      Webrtc: null,
      WebrtcConnectTimeout: 15000,
      CallRequestQueues: [],
      RequestCenters: [],
      NeedDataChannel: false,
    };
  },
  watch: {
    teamValue() {
      if (this.teamValue && this.user) {
        this.Identity = `UserId=${this.user.UsId} Device=Web TeamId=${this.teamValue}`;
        this.OpenWebsocket();
        this.InitWebrtc();
      }
    },
    user() {
      if (this.user && this.teamValue) {
        this.Identity = `UserId=${this.user.UsId} Device=Web TeamId=${this.teamValue}`;
        this.OpenWebsocket();
        this.InitWebrtc();
      }
    },
  },
  computed: {
    ...mapState(["user"]),
    cmUrl() {
      return process.env.VUE_APP_CMURL;
    },
  },
  methods: {
    changeWebrtcDataType(functions) {
      this.Webrtc.ClientInfo.WebrtcDataType = functions;
    },
    /**
     * 图片地址
     */
    handleImageSrc(imageName) {
      if (!this.isLocalStorage) {
        return this.cmUrl + imageName;
      } else {
        return "data:image/jepg;base64," + imageName;
      }
    },
    WebsocketOnConnectionStateChange: (websocket, state) => {},
    WebrtcOnConnectionStateChange: (room, from, state) => {},
    WebrtcOnRemoteTrack: (peerConnection, event) => {},
    WebrtcOnDataChannelBytes: (rtcDataChannel, bytes, datapackType) => {},
    OpenWebsocket() {
      var websocketUrl = this.WebsocketUrl;
      if (!websocketUrl) {
        websocketUrl = `${Vue.prototype.$socketUrl}/socket/webrtc/ws.ashx`;
      }
      var _this = this;
      if (_this.WebSocket) {
        try {
          _this.WebSocket.destroy();
        } catch (error) {}
      }
      //是否重连
      let url = websocketUrl + `?token=${Vue.prototype.$xStorage.getItem("token")}&identity=${_this.Identity}`;
      let isReconnect = false;
      if (_this.Identity) {
        _this.WebSocket = new xSocket({
          isOut: true,
          heartMsg: "HEARTBEAT",
          url: url,
          onopen: function (e) {
            _this.WebsocketConnected = true;
            _this.WebsocketOnConnectionStateChange?.(_this.WebSocket, "open");
          },
          reconnect() {
            isReconnect = true;
          },
          onclose: function (e) {
            if (!isReconnect && !_this.isDisposed) {
              _this.WebsocketConnected = false;
              _this.WebsocketOnConnectionStateChange?.(_this.WebSocket, "close");
            }
          },
          onmessage: function (e) {
            if (e.data == "HEARTBEAT") {
              return;
            }
            if (_this.Webrtc) {
              _this.Webrtc.SignalingMessageReceived(e.data);
            }
          },
        });
      }
    },
    CloseWebsocket() {
      if (this.WebSocket) {
        this.WebSocket.destroy();
      }
    },
    InitWebrtc() {
      var _this = this;
      if (_this.Webrtc) {
        _this.Webrtc.Close();
        _this.Webrtc = null;
      }
      var clientInfo = new ClientInfo(this.Identity);
      clientInfo.WebrtcDataType = this.Functions;
      this.Webrtc = new Webrtc(clientInfo);
      window.webrtc = this.Webrtc;
      this.Webrtc.SignalingSendMessage = (data) => {
        if (_this.WebSocket) {
          _this.WebSocket.webSocketObj.send(data);
        } else {
          throw new Error("信号通道未连接");
        }
      };
      this.Webrtc.OnConnectionStateChange = (room, from, state) => {
        var peerConnection = _this.Webrtc.GetPeerConnection(room, from);
        var callRequest = _this.CallRequestQueues.find((m) => m.client.Equals(from));
        if (callRequest) {
          var removeCallRequest = true;
          if (state == "open") {
            if (!callRequest.needDataChannel) {
              callRequest.resolve(peerConnection);
            } else {
              removeCallRequest = false;
            }
          } else {
            // callRequest.reject("断开连接");
            return;
          }
          if (removeCallRequest) {
            callRequest.stopTime = new Date();
            clearTimeout(callRequest.timeoutId);
            var index = _this.CallRequestQueues.findIndex((item) => item == callRequest);
            if (index != -1) {
              _this.CallRequestQueues.splice(index, 1);
            }
          }
        }
        _this.WebrtcOnConnectionStateChange?.(room, from, state);
      };
      this.Webrtc.OnRemoteTrack = (peerConnection, event) => {
        _this.WebrtcOnRemoteTrack?.(peerConnection, event);
      };
      this.Webrtc.OnDataChannelStateChange = (rtcDataChannel, state) => {
        if (state == "open") {
          var requestCenter = new RequestCenter(rtcDataChannel);
          _this.RequestCenters.push(requestCenter);
          var client = rtcDataChannel.RTCPeerConnection.To;
          var callRequest = _this.CallRequestQueues.find((m) => m.client.Equals(client));
          if (callRequest && callRequest.needDataChannel) {
            setTimeout(() => {
              callRequest.resolve(rtcDataChannel.RTCPeerConnection);
              callRequest.stopTime = new Date();
              clearTimeout(callRequest.timeoutId);
              let index = _this.CallRequestQueues.findIndex((item) => item == callRequest);
              if (index != -1) {
                _this.CallRequestQueues.splice(index, 1);
              }
            }, 1000);
          }
        } else {
          let index = _this.RequestCenters.findIndex((item) => item.RTCDataChannel == rtcDataChannel);
          if (index != -1) {
            _this.RequestCenters.splice(index, 1);
          }
        }
      };
      this.Webrtc.OnDataChannelBytes = (rtcDataChannel, bytes, datapackType) => {
        if (datapackType == DatapackType.Response) {
          var requestCenter = this.GetRequestCenter(rtcDataChannel.RTCPeerConnection.Room, rtcDataChannel.RTCPeerConnection.To);
          if (requestCenter) {
            requestCenter.HandleResponse(bytes);
          }
        }
        _this.WebrtcOnDataChannelBytes?.(rtcDataChannel, bytes, datapackType);
      };
    },
    CallRequest(clientIdentity, action) {
      var _this = this;
      var promise = new Promise((resolve, reject) => {
        var client = new ClientInfo(clientIdentity);
        // client.WebrtcDataType = "Screen,Data";
        var peerConnection = _this.Webrtc.GetPeerConnection(null, client);
        if (action == "Join") {
          if (peerConnection && peerConnection.PeerConnection.connectionState == "connected") {
            resolve(peerConnection);
            return;
          }
        } else {
          if (!peerConnection) {
            reject("连接未建立");
            return;
          }
        }
        if (!_this.WebsocketConnected) {
          reject("websocket未连接");
          return;
        }
        var callRequest = this.CallRequestQueues.find((m) => m.client.Equals(client));
        if (callRequest) {
          reject("请勿重复请求");
          return;
        }
        var timeoutId = setTimeout(() => {
          var peerConnection = _this.Webrtc.GetPeerConnection(null, client);
          if (peerConnection) {
            peerConnection.Close();
          }
          var index = _this.CallRequestQueues.findIndex((item) => item == callRequest);
          if (index != -1) {
            _this.CallRequestQueues.splice(index, 1);
          }
          reject("连接超时");
        }, _this.WebrtcConnectTimeout);
        callRequest = {
          needDataChannel: _this.NeedDataChannel,
          startTime: new Date(),
          endTime: null,
          timeoutId: timeoutId,
          client: client,
          resolve: resolve,
          reject: reject,
        };
        _this.CallRequestQueues.push(callRequest);
        try {
          if (action == "Join") {
            _this.Webrtc.Call(client);
          } else {
            _this.Webrtc.HangUp(client);
          }
        } catch (error) {
          clearTimeout(callRequest.timeoutId);
          var index = _this.CallRequestQueues.findIndex((item) => item == callRequest);
          if (index != -1) {
            _this.CallRequestQueues.splice(index, 1);
          }
          reject("连接失败");
        }
      });
      return promise;
    },
    Call(clientIdentity) {
      return this.CallRequest(clientIdentity, "Join");
    },
    HangUp(clientIdentity) {
      return this.CallRequest(clientIdentity, "Leave");
    },
    HangUpAll() {
      this.Webrtc?.HangUpAll();
    },
    SendRequest(roomIdentity, clientIdentity, request) {
      var room = null;
      if (roomIdentity) {
        room = new RoomInfo(roomIdentity);
      }
      var client = null;
      if (clientIdentity) {
        client = new ClientInfo(clientIdentity);
      }
      var requestCenter = this.GetRequestCenter(room, client);
      if (requestCenter) {
        requestCenter.SendRequest(request);
        return true;
      }
      return false;
    },
    async SendRequestAsync(identity, request) {
      await this.Call(identity);
      this.SendRequest(null, identity, request);
    },
    GetRequestCenter(room, client) {
      var peerConnection = this.Webrtc.GetPeerConnection(room, client);
      if (peerConnection) {
        var dataChannels = peerConnection.GetRTCDataChannels();
        if (dataChannels.length > 0) {
          var dataChannel = dataChannels[0];
          var requestCenter = this.RequestCenters.find((m) => m.RTCDataChannel == dataChannel);
          return requestCenter;
        }
        return null;
      }
    },
    CloseWebrtc() {
      this.Webrtc?.Close();
    },
    TracksToMediaStream(tracks) {
      var mediaStream = new MediaStream();
      tracks.forEach((track) => {
        mediaStream.addTrack(track);
      });
      return mediaStream;
    },
  },
  beforeDestroy() {
    this.CloseWebrtc();
    this.CloseWebsocket();
  },
};
