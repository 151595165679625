export default class RoomInfo {
  constructor(identity, multiMode) {
    this.Identity = identity;
    this.MultiMode = multiMode || false;
    this.WebrtcDataType = "Screen,Data"; //None,Screen,Data
    this.ForceDataType = false;
    this.Owner = null;
    this.IsOwner = function (clientInfo) {
      if (clientInfo == null) {
        return false;
      }
      if (this.Owner != null) {
        return this.Owner.Equals(clientInfo);
      }
      return false;
    };
    this.Equals = function (other) {
      if (other == null) {
        return false;
      }
      var equal = this.Identity == other.Identity;
      return equal;
    };
  }
}
