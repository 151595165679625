export default class ClientInfo {
  constructor(identity) {
    this.Identity = identity;
    this.WebrtcDataType = "Screen,Data"; //None,Screen,Data,Camera,Audio
    this.Equals = function (other) {
      if (other == null) {
        return false;
      } else {
        var equal = this.Identity == other.Identity;
        return equal;
      }
    };
  }
}
