export default {
  /// <summary>
  /// 发起人，调用CreateOffer
  /// </summary>
  Initiator: 0,
  /// <summary>
  /// 响应人
  /// </summary>
  Target: 1,
};
