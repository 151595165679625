import MediaTrackSourceBase from "./MediaTrackSourceBase";

export default class MediaTrackSourceAudio extends MediaTrackSourceBase {
  async GetTrackSource() {
    if (this.TrackSource == null) {
      const constraints = {
        audio: true,
      };
      this.TrackSource = await navigator.mediaDevices.getUserMedia(constraints);
    }
    return this.TrackSource;
  }
}
