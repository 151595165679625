export class RTCPeerConnection {
  constructor(connection, room, from, to) {
    this.PeerConnection = connection;
    this.Room = room;
    this.From = from;
    this.To = to;
    this.RTCDataChannels = [];
    this.OnClosed = null;
    this.OnDataChannelStateChange = null;
  }
  AddDataChannel(dataChannel) {
    this.RTCDataChannels.push(dataChannel);
    this.OnDataChannelStateChange?.(dataChannel, true);
  }
  RemoveDataChannel(dataChannel) {
    var index = this.RTCDataChannels.findIndex((item) => item == dataChannel);
    if (index != -1) {
      this.RTCDataChannels.splice(index, 1);
    }
    this.OnDataChannelStateChange?.(dataChannel, false);
  }
  GetRTCDataChannels() {
    return this.RTCDataChannels;
  }
  CheckRoom(room) {
    if (this.Room == room) {
      return true;
    }
    if (this.Room != null) {
      return this.Room.Equals(room);
    }
    return false;
  }
  Close() {
    this.RTCDataChannels = [];
    this.PeerConnection?.close();
    if (this.OnClosed) {
      this.OnClosed(this);
      this.OnClosed = null;
    }
    this.OnDataChannelStateChange = null;
  }
}
