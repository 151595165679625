export default class MessageQueue {
  constructor(dataChannel) {
    this.RTCDataChannel = dataChannel;
    this.IntervalId = null;
    this.Messages = [];
  }
  Add(message) {
    this.Messages.push(message);
  }
  Run() {
    if (!this.IntervalId) {
      this.IntervalId = setInterval(() => {
        this.Send();
      }, 10);
    }
  }
  Stop() {
    clearInterval(this.IntervalId);
    this.IntervalId = null;
  }
  Send() {
    let message = null;
    if (this.Messages.length > 0) {
      message = this.Messages.pop();
    }
    if (message != null) {
      try {
        this.RTCDataChannel.Send(message);
      } catch (error) {
        //ignore
      }
    }
  }
}
