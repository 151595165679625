import MessageQueue from "./transport/MessageQueue";
import Message from "./transport/Message";
import Tools from "./transport/Tools";

export default class RTCDataChannel {
  constructor(connection, dataChannel) {
    dataChannel.binaryType = "arraybuffer";
    this.RTCPeerConnection = connection;
    this.DataChannel = dataChannel;
    this.MessageQueue = new MessageQueue(this);
    this.MessageHeader = [];
    this.Requests = [];
    this.Message = null;
    this.OnMessageReceived = null;
    var _this = this;
    this.DataChannel.bufferedAmountLowThreshold = 65536;
    this.DataChannel.onopen = function () {
      _this.RTCPeerConnection.AddDataChannel(_this);
      _this.Open();
    };
    this.DataChannel.onclose = function () {
      _this.RTCPeerConnection.RemoveDataChannel(_this);
      _this.Close();
    };
    this.DataChannel.onmessage = (event) => {
      _this.SaveMessage(event.data);
    };
  }
  Open() {
    this.MessageQueue.Run();
  }
  Close() {
    this.OnMessageReceived = null;
    this.MessageHeader = [];
    this.MessageQueue.Stop();
  }
  SaveMessage(bytes) {
    var _self = this;
    var tools = Tools;
    new Uint8Array(bytes).forEach((data) => {
      if (_self.Message == null) {
        if (_self.MessageHeader.length == 5) {
          _self.MessageHeader.shift();
          _self.MessageHeader.push(data);
        } else {
          _self.MessageHeader.push(data);
        }
        if (_self.MessageHeader.length == 5) {
          try {
            var type = _self.MessageHeader.shift();
            var datapackType = tools.toInt8([type]);
            var bytesLength = _self.MessageHeader;
            var messageLength = tools.BytesToInt(bytesLength);
            if (messageLength > 0) {
              _self.Message = new Message(messageLength, datapackType);
            }
            _self.MessageHeader = [];
          } catch (error) {
            //
          }
        }
      } else {
        if (!_self.Message.IsComplete()) {
          _self.Message.Add(data);
        }
        if (_self.Message.IsComplete()) {
          _self.OnMessageReceived(_self, _self.Message.GetBytes(), _self.Message.GetDatapackType());
          _self.Message = null;
        }
      }
    });
  }
  SendAsync(bytes, datapackType) {
    var messageBuilder = new Message();
    var data = messageBuilder.BuildMessage(bytes, datapackType);
    this.MessageQueue.Add(data);
  }
  Send(bytes, datapackType) {
    if (datapackType == undefined) {
      // while (this.DataChannel.bufferedAmount >= this.DataChannel.bufferedAmountLowThreshold) {}
      this.DataChannel.send(bytes);
    } else {
      var message = new Message();
      var data = message.BuildMessage(bytes, datapackType);
      this.Send(data);
    }
  }
}
