import { v4 as uuidv4 } from "uuid";
export default class Request {
  constructor() {
    this.RequestId = uuidv4();
    this.Url = null;
    this.Headers = {};
    this.ContentType = "Text"; //Text,Json
    this.Body = null;
    this.Timeout = 60000;
    this.TimeoutId = null;
    this.OnResponse = null;
    this.OnError = null;
    this.OnTimeout = null;
  }
  StartCalcTimeout() {
    if (this.Timeout <= 0) {
      return;
    }
    this.TimeoutId = setTimeout(() => {
      this.OnTimeout?.(new Error("执行请求超时"));
      this.OnError?.(new Error("执行请求超时"));
    }, this.Timeout);
  }
  StopCalcTimeout() {
    if (this.TimeoutId) {
      clearTimeout(this.TimeoutId);
      this.TimeoutId = null;
    }
  }
}
