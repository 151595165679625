import MessageBase from "./MessageBase";
export default class MessageTarget extends MessageBase {
  constructor(room, from, to, data, dataType) {
    super("Target");
    this.Room = room;
    this.From = from;
    this.To = to;
    this.Data = data;
    this.DataType = dataType;
  }
}
