class Tools {
  /**
   * 将整数转换为byte数组并指定长度
   *
   * @param a      整数
   * @param length 指定长度
   * @return
   */
  IntToBytes(a, length) {
    let bs = new Uint8Array(length);
    for (let i = bs.length - 1; i >= 0; i--) {
      bs[i] = a % 255;
      a = a / 255;
    }
    return bs;
  }

  /**
   * 将byte数组转换为整数
   *
   * @param bs
   * @return
   */
  BytesToInt(bs) {
    let a = 0;
    for (let i = bs.length - 1; i >= 0; i--) {
      a += bs[i] * Math.pow(255, bs.length - i - 1);
    }
    return a;
  }
  //构建一个视图，把字节数组写到缓存中，索引从0开始，大端字节序
  getView(bytes) {
    var view = new DataView(new ArrayBuffer(bytes.length));
    for (var i = 0; i < bytes.length; i++) {
      view.setUint8(i, bytes[i]);
    }
    return view;
  }
  //将字节数组转成有符号的8位整型，大端字节序
  toInt8(bytes) {
    return this.getView(bytes).getInt8();
  }
  //将字节数组转成无符号的8位整型，大端字节序
  toUint8(bytes) {
    return this.getView(bytes).getUint8();
  }
  //将字节数组转成有符号的16位整型，大端字节序
  toInt16(bytes) {
    return this.getView(bytes).getInt16();
  }
  //将字节数组转成无符号的16位整型，大端字节序
  toUint16(bytes) {
    return this.getView(bytes).getUint16();
  }
  //将字节数组转成有符号的32位整型，大端字节序
  toInt32(bytes) {
    return this.getView(bytes).getInt32();
  }
  //将字节数组转成无符号的32位整型，大端字节序
  toUint32(bytes) {
    return this.getView(bytes).getUint32();
  }
  //将字节数组转成32位浮点型，大端字节序
  toFloat32(bytes) {
    return this.getView(bytes).getFloat32();
  }
  //将字节数组转成64位浮点型，大端字节序
  toFloat64(bytes) {
    return this.getView(bytes).getFloat64();
  }

  //将数值写入到视图中，获得其字节数组，大端字节序
  getUint8Array(len, setNum) {
    var buffer = new ArrayBuffer(len); //指定字节长度
    setNum(new DataView(buffer)); //根据不同的类型调用不同的函数来写入数值
    return new Uint8Array(buffer); //创建一个字节数组，从缓存中拿取数据
  }
  //得到一个8位有符号整型的字节数组，大端字节序
  getInt8Bytes(num) {
    return this.getUint8Array(1, function (view) {
      view.setInt8(0, num);
    });
  }
  //得到一个8位无符号整型的字节数组，大端字节序
  getUint8Bytes(num) {
    return this.getUint8Array(1, function (view) {
      view.setUint8(0, num);
    });
  }
  //得到一个16位有符号整型的字节数组，大端字节序
  getInt16Bytes(num) {
    return this.getUint8Array(2, function (view) {
      view.setInt16(0, num);
    });
  }
  //得到一个16位无符号整型的字节数组，大端字节序
  getUint16Bytes(num) {
    return this.getUint8Array(2, function (view) {
      view.setUint16(0, num);
    });
  }
  //得到一个32位有符号整型的字节数组，大端字节序
  getInt32Bytes(num) {
    return this.getUint8Array(4, function (view) {
      view.setInt32(0, num);
    });
  }
  //得到一个32位无符号整型的字节数组，大端字节序
  getUint32Bytes(num) {
    return this.getUint8Array(4, function (view) {
      view.setUint32(0, num);
    });
  }
  //得到一个32位浮点型的字节数组，大端字节序
  getFloat32Bytes(num) {
    return this.getUint8Array(4, function (view) {
      view.setFloat32(0, num);
    });
  }
  //得到一个64位浮点型的字节数组，大端字节序
  getFloat64Bytes(num) {
    return this.getUint8Array(8, function (view) {
      view.setFloat64(0, num);
    });
  }
}

export default new Tools();
