export default {
  /// <summary>
  /// webrtc建立连接消息
  /// </summary>
  Offer: "Offer",
  Answer: "Answer",
  Candidate: "Candidate",
  /// <summary>
  /// webrtc通讯消息
  /// </summary>
  Text: "Text",
};
