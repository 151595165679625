import MessageType from "./models/message/MessageType";
import RoomInfo from "./models/RoomInfo";
import ClientInfo from "./models/ClientInfo";
import WebrtcDataType from "./models/WebrtcDataType";
import MessageJoin from "./models/message/MessageJoin";
import MessageLeave from "./models/message/MessageLeave";
import MessageCall from "./models/message/MessageCall";
import MessageTarget from "./models/message/MessageTarget";
import DataType from "./models/message/DataType";
import { RTCPeerConnection as CustomRTCPeerConnection } from "./RTCPeerConnection";
import RTCDataChannel from "./RTCDataChannel";
import ClientType from "./ClientType";
import MediaTrackSourceCenter from "./mediaTrackSource/MediaTrackSourceCenter";
import * as Logger from "./logger";
export default class Webrtc {
  constructor(clientInfo) {
    this.ClientInfo = clientInfo;
    this.PeerConnections = [];
    this.OnConnectionStateChange = null;
    this.OnRemoteTrack = null;
    this.OnDataChannelStateChange = null;
    this.OnDataChannelBytes = null;
    this.SignalingSendMessage = null;
    this.MediaTrackSourceCenter = MediaTrackSourceCenter.GetInstance();
    // Logger.enable();
  }
  async SignalingMessageReceived(data) {
    try {
      var message = JSON.parse(data);
      if (message.Type == MessageType.Join) {
        if (message == null || message.Room == null || message.Client == null) {
          return;
        }
        if (message.Room) {
          message.Room.__proto__ = new RoomInfo();
        }
        message.Client.__proto__ = new ClientInfo();
        this.HandleJoin.call(this, message.Room, message.Client);
      } else if (message.Type == MessageType.Leave) {
        if (message == null || message.Room == null || message.Client == null) {
          return;
        }
        if (message.Room) {
          message.Room.__proto__ = new RoomInfo();
        }
        message.Client.__proto__ = new ClientInfo();
        this.HandleLeave.call(this, message.Room, message.Client);
      } else if (message.Type == MessageType.Call) {
        if (message == null || message.From == null || message.To == null) {
          return;
        }
        message.From.__proto__ = new ClientInfo();
        message.To.__proto__ = new ClientInfo();
        this.HandleCall.call(this, message.From);
      } else if (message.Type == MessageType.Target) {
        if (message.Room) {
          message.Room.__proto__ = new RoomInfo();
        }
        message.From.__proto__ = new ClientInfo();
        message.To.__proto__ = new ClientInfo();
        if (message.DataType == DataType.Offer) {
          let dataMessage = message.Data;
          let peerConnection = null;
          console.log("offer");
          peerConnection = this.GetPeerConnection(message.Room, message.From);
          if (peerConnection) {
            console.log("close exist");
            peerConnection.Close();
          }
          try {
            peerConnection = await this.CreatePeerConnection(
              message.Room,
              message.From,
              ClientType.Target
            );
            await peerConnection.PeerConnection.setRemoteDescription(
              dataMessage
            );
            var answer = await peerConnection.PeerConnection.createAnswer();
            await peerConnection.PeerConnection.setLocalDescription(answer);
            var messageAnswer = new MessageTarget(
              peerConnection.Room,
              peerConnection.From,
              peerConnection.To,
              answer,
              "Answer"
            );
            this.SignalingSendMessage(JSON.stringify(messageAnswer));
          } catch (ex) {
            console.error(ex);
            if (peerConnection) {
              peerConnection.Close();
            }
          }
        } else if (message.DataType == DataType.Answer) {
          let dataMessage = message.Data;
          let peerConnection = this.GetPeerConnection(
            message.Room,
            message.From
          );
          if (peerConnection) {
            try {
              await peerConnection.PeerConnection.setRemoteDescription(
                dataMessage
              );
            } catch (ex) {
              console.error(ex);
            }
          }
        } else if (message.DataType == DataType.Candidate) {
          let dataMessage = message.Data;
          let peerConnection = this.GetPeerConnection(
            message.Room,
            message.From
          );
          if (peerConnection) {
            try {
              await peerConnection.PeerConnection.addIceCandidate(dataMessage);
            } catch (ex) {
              console.error(ex);
            }
          }
        } else if (message.DataType == DataType.Text) {
          let dataMessage = message.Data?.ToString();
        }
      }
    } catch (ex) {
      console.error(ex);
    }
  }
  async HandleCall(client) {
    var peerConnection = this.GetPeerConnection(null, client);
    if (peerConnection) {
      peerConnection.Close();
    }
    await this.SendOffer(null, client);
  }
  async HandleJoin(room, client) {
    var isSelf = client.Equals(this.ClientInfo);
    if (!isSelf) {
      var peerConnection = this.GetPeerConnection(room, client);
      if (peerConnection) {
        peerConnection.Close();
      }
      await this.SendOffer(room, client);
    } else {
      this.OnConnectionStateChange?.(room, client, "open");
    }
  }
  HandleLeave(room, client) {
    var peerConnection = this.GetPeerConnection(room, client);
    if (peerConnection) {
      this.OnConnectionStateChange?.(room, client, "closed");
    }
    var peerConnections = this.PeerConnections.filter(
      (m) => m.CheckRoom(room) && m.To.Equals(client)
    );
    peerConnections.forEach((peerConnection) => {
      peerConnection.Close();
    });
  }
  Join(room) {
    var messageJoin = new MessageJoin(room, this.ClientInfo);
    this.SignalingSendMessage(JSON.stringify(messageJoin));
  }
  Leave(room) {
    var peerConnections = this.PeerConnections.filter((m) => m.CheckRoom(room));
    peerConnections.forEach((peerConnection) => {
      peerConnection.Close();
    });
    var messageLeave = new MessageLeave(room, this.ClientInfo);
    this.SignalingSendMessage(JSON.stringify(messageLeave));
  }
  LeaveAll() {
    var peerConnections = this.PeerConnections.filter((m) => m.Room == null);
    peerConnections.forEach((peerConnection) => {
      this.Leave(peerConnection.Room);
    });
  }
  async SendOffer(room, client) {
    var peerConnection = await this.CreatePeerConnection(
      room,
      client,
      ClientType.Initiator
    );
    try {
      var offer = await peerConnection.PeerConnection.createOffer();
      await peerConnection.PeerConnection.setLocalDescription(offer);
      var messageOffer = new MessageTarget(
        peerConnection.Room,
        peerConnection.From,
        peerConnection.To,
        offer,
        "Offer"
      );
      this.SignalingSendMessage(JSON.stringify(messageOffer));
    } catch {
      peerConnection.Close();
    }
  }
  async CreatePeerConnection(room, client, clientType) {
    var _this = this;
    const configuration = {
      iceServers: [
        {
          urls: "stun:stun.services.mozilla.com",
        },
        {
          urls: "turn:test.workeases.com:3478?transport=udp",
          username: "whb",
          credential: "123456",
        },
      ],
    };
    var createDataChannel = false;
    var createScreenTransceiver = false;
    var createCameraTransceiver = false;
    var createAudioTransceiver = false;
    var webrtcDataType = this.ClientInfo.WebrtcDataType;
    if (room != null && room.ForceDataType) {
      webrtcDataType = room.WebrtcDataType;
    }
    if (
      (room == null ||
        room.WebrtcDataType.indexOf(WebrtcDataType.Data) != -1) &&
      webrtcDataType.indexOf(WebrtcDataType.Data) != -1 &&
      client.WebrtcDataType.indexOf(WebrtcDataType.Data) != -1
    ) {
      if (clientType == ClientType.Initiator) {
        createDataChannel = true;
      }
    }
    if (
      (room == null ||
        room.WebrtcDataType.indexOf(WebrtcDataType.Screen) != -1) &&
      (webrtcDataType.indexOf(WebrtcDataType.Screen) != -1 ||
        client.WebrtcDataType.indexOf(WebrtcDataType.Screen) != -1)
    ) {
      if (clientType == ClientType.Initiator) {
        createScreenTransceiver = true;
      }
    }
    if (
      (room == null ||
        room.WebrtcDataType.indexOf(WebrtcDataType.Camera) != -1) &&
      (webrtcDataType.indexOf(WebrtcDataType.Camera) != -1 ||
        client.WebrtcDataType.indexOf(WebrtcDataType.Camera) != -1)
    ) {
      if (clientType == ClientType.Initiator) {
        createCameraTransceiver = true;
      }
    }
    if (
      (room == null ||
        room.WebrtcDataType.indexOf(WebrtcDataType.Audio) != -1) &&
      (webrtcDataType.indexOf(WebrtcDataType.Audio) != -1 ||
        client.WebrtcDataType.indexOf(WebrtcDataType.Audio) != -1)
    ) {
      if (clientType == ClientType.Initiator) {
        createAudioTransceiver = true;
      }
    }
    var connection = new RTCPeerConnection(configuration);
    var from = this.ClientInfo;
    var peerConnection = new CustomRTCPeerConnection(
      connection,
      room,
      from,
      client
    );
    //create dataChannel
    if (createDataChannel) {
      var dataChannel = connection.createDataChannel("request_channel");
      var rtcDataChannel = new RTCDataChannel(peerConnection, dataChannel);
      rtcDataChannel.OnMessageReceived = (sender, bytes, datapackType) => {
        _this.OnDataChannelBytes?.(sender, bytes, datapackType);
      };
    }
    //create transceiver，必须先创建transceiver，addtrack会自动使用
    if (createScreenTransceiver) {
      connection.addTransceiver("video", { direction: "sendrecv" });
    }
    if (createCameraTransceiver) {
      connection.addTransceiver("video", { direction: "sendrecv" });
    }
    if (createAudioTransceiver) {
      connection.addTransceiver("audio", { direction: "sendrecv" });
    }
    //create track
    var trackScreen = null;
    var trackCamera = null;
    var trackAudio = null;
    if (
      (room == null ||
        room.WebrtcDataType.indexOf(WebrtcDataType.Screen) != -1) &&
      webrtcDataType.indexOf(WebrtcDataType.Screen) != -1
    ) {
      var streamScreen =
        await this.MediaTrackSourceCenter.MediaTrackSourceScreen.GetTrackSource();
      this.MediaTrackSourceCenter.MediaTrackSourceScreen.Use();
      trackScreen = streamScreen.clone();
      if (streamScreen.active) {
        for (const track of streamScreen.getTracks()) {
          connection.addTrack(track);
        }
      }
    }
    if (
      (room == null ||
        room.WebrtcDataType.indexOf(WebrtcDataType.Camera) != -1) &&
      webrtcDataType.indexOf(WebrtcDataType.Camera) != -1
    ) {
      var streamCamera =
        await this.MediaTrackSourceCenter.MediaTrackSourceCamera.GetTrackSource();
      this.MediaTrackSourceCenter.MediaTrackSourceCamera.Use();
      trackCamera = streamCamera.clone();
      if (streamCamera.active) {
        for (const track of streamCamera.getTracks()) {
          connection.addTrack(track);
        }
      }
    }
    if (
      (room == null ||
        room.WebrtcDataType.indexOf(WebrtcDataType.Audio) != -1) &&
      webrtcDataType.indexOf(WebrtcDataType.Audio) != -1
    ) {
      var streamAudio =
        await this.MediaTrackSourceCenter.MediaTrackSourceAudio.GetTrackSource();
      this.MediaTrackSourceCenter.MediaTrackSourceAudio.Use();
      trackAudio = streamAudio.clone();
      if (streamAudio.active) {
        for (const track of streamAudio.getTracks()) {
          connection.addTrack(track);
        }
      }
    }
    if (clientType == ClientType.Initiator) {
      connection.onnegotiationneeded = async function (event) {
        Logger.log("onnegotiationneeded");
        try {
          var offer = await connection.createOffer();
          await connection.setLocalDescription(offer);
          var messageOffer = new MessageTarget(
            peerConnection.Room,
            peerConnection.From,
            peerConnection.To,
            offer,
            "Offer"
          );
          _this.SignalingSendMessage(JSON.stringify(messageOffer));
        } catch (err) {
          //ignore
        }
      };
    }
    connection.onsignalingstatechange = (event) => {
      Logger.log(`onsignalingstatechange:${connection.signalingState}`);
    };
    connection.onicegatheringstatechange = (event) => {
      Logger.log(`onicegatheringstatechange:${connection.iceGatheringState}'`);
    };
    connection.oniceconnectionstatechange = function (event) {
      Logger.log("oniceconnectionstatechange:" + connection.iceConnectionState);
      switch (connection.iceConnectionState) {
        case "disconnected":
        case "failed":
          peerConnection.Close();
          break;
      }
    };
    connection.onconnectionstatechange = function (event) {
      Logger.log("onconnectionstatechange:" + connection.connectionState);
      if (connection.connectionState == "connected") {
        _this.OnConnectionStateChange?.(room, client, "open");
      }
    };
    connection.onicecandidate = function (event) {
      if (event.candidate) {
        var messageCandidate = new MessageTarget(
          peerConnection.Room,
          peerConnection.From,
          peerConnection.To,
          event.candidate,
          "Candidate"
        );
        _this.SignalingSendMessage(JSON.stringify(messageCandidate));
      } else {
        // All ICE candidates have been sent
      }
    };
    //bind dataChannel
    peerConnection.OnDataChannelStateChange = (rtcDataChannel, state) => {
      //LoggerHelper._.Info($"datachannel: {rtcDataChannel.DataChannel.Label} change state {rtcDataChannel.DataChannel.State}");
      if (state) {
        _this.OnDataChannelStateChange?.(rtcDataChannel, "open");
      } else {
        _this.OnDataChannelStateChange?.(rtcDataChannel, "closed");
      }
    };
    connection.ondatachannel = function (event) {
      Logger.log("ondatachannel:" + event);
      var rtcDataChannel = new RTCDataChannel(peerConnection, event.channel);
      rtcDataChannel.OnMessageReceived = (sender, bytes, datapackType) => {
        _this.OnDataChannelBytes?.(sender, bytes, datapackType);
      };
    };
    //bind track
    connection.ontrack = function (event) {
      Logger.log(
        "ontrack:" +
          "kind:" +
          event.track.kind +
          "id:" +
          event.track.id +
          "label:" +
          event.track.label
      );
      if (_this.OnRemoteTrack) {
        _this.OnRemoteTrack(peerConnection, event);
      }
    };
    peerConnection.OnClosed = function (peerConnection) {
      peerConnection.ontrack = null;
      peerConnection.onremovetrack = null;
      peerConnection.onremovestream = null;
      peerConnection.onicecandidate = null;
      peerConnection.oniceconnectionstatechange = null;
      peerConnection.onsignalingstatechange = null;
      peerConnection.onicegatheringstatechange = null;
      peerConnection.onnegotiationneeded = null;

      if (trackScreen != null) {
        trackScreen.getTracks().forEach((track) => track.stop());
        _this.MediaTrackSourceCenter.MediaTrackSourceScreen.Dispose();
      }
      if (trackCamera != null) {
        trackCamera.getTracks().forEach((track) => track.stop());
        _this.MediaTrackSourceCenter.MediaTrackSourceCamera.Dispose();
      }
      if (trackAudio != null) {
        trackAudio.getTracks().forEach((track) => track.stop());
        _this.MediaTrackSourceCenter.MediaTrackSourceAudio.Dispose();
      }
      var index = _this.PeerConnections.findIndex(
        (item) => item == peerConnection
      );
      if (index != -1) {
        _this.PeerConnections.splice(index, 1);
      }
      _this.OnConnectionStateChange?.(room, client, "closed");
    };
    this.PeerConnections.push(peerConnection);
    return peerConnection;
  }
  GetPeerConnection(room, client) {
    var peerConnection = this.PeerConnections.find(
      (m) => m.CheckRoom(room) && m.To.Equals(client)
    );
    return peerConnection;
  }
  Call(client) {
    var messageCall = new MessageCall(this.ClientInfo, client);
    this.SignalingSendMessage(JSON.stringify(messageCall));
  }
  HangUp(client) {
    var peerConnections = this.PeerConnections.filter(
      (m) => m.Room == null && m.To.Equals(client)
    );
    peerConnections.forEach((peerConnection) => {
      peerConnection.Close();
    });
  }
  HangUpAll() {
    var peerConnections = this.PeerConnections.filter((m) => m.Room == null);
    peerConnections.forEach((peerConnection) => {
      this.HangUp(peerConnection.To);
    });
  }
  Close() {
    this.PeerConnections.forEach((peerConnection) => {
      peerConnection.Close();
    });
    this.PeerConnections = [];
  }
}
